import {initialState} from '../initialState'
import {LOAD_EMPLOYEE_DATA, SET_EMPLOYEE_DATA, SET_EMPLOYEE_DATA_ERROR} from "../../actions/employeeActions";

export const employeeReducer = (
    state = initialState.employeeReducer,
    action,
) => {
    switch (action.type) {
        case SET_EMPLOYEE_DATA:
            localStorage.setItem('employeeData', JSON.stringify(action.payload.employeeData))
            return {
                ...state,
                employeeData: action.payload.employeeData,
            }
        case SET_EMPLOYEE_DATA_ERROR:
            return {
                ...state,
                error: action.payload.error,
            }
        case LOAD_EMPLOYEE_DATA:
            return {
                ...state,
                employeeData: action.payload.employeeData,
            }
        default:
            return { ...state }
    }
}
