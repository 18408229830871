import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';
import Login from "./pages/Login/Login";
import Video from "./pages/Video/Video";
import Finish from "./pages/Finish/Finish";
import configureStore from "./redux/store";
import {initialState} from "./redux/reducers/initialState";
import {Provider} from "react-redux";

const store = configureStore(initialState)

function App() {

    const router = createBrowserRouter([
        {
            path: "/:eventId",
            element: <Login/>,
        },
        {
            path: "/:eventId/video",
            element: <Video/>,
        },
        {
            path: "/:eventId/finish",
            element: <Finish/>,
        },
    ], { basename: "/" });

    return (
        <Provider store={store}>
            <ChakraProvider>
                <RouterProvider router={router}/>
            </ChakraProvider>
        </Provider>
    );
}

export default App;
