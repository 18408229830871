export const getEventInfoFromAPI = (data) => {
    return {
        id: data.id,
        eventId: data.attributes?.eventId || '',
        logoUrl: data.attributes?.logo?.data?.attributes?.url || '',
        backgroundImageUrl: data.attributes?.background?.data?.attributes?.url || '',
        eventName: data.attributes?.name || '',
        eventColor: data.attributes?.color || '',
        eventSecondaryColor: data.attributes?.secondary_color || '',
    }
}
