export const initialState = {
    videoReducer: {
        videosByRole: [],
        selectedVideo: null,
    },
    employeeReducer: {
        employeeData: null,
        error: ''
    },
    eventReducer: {
        eventData: null,
        error: ''
    }
}
