import {
    getEmployeeInfo,
    getEmployeeInfoById,
    postEmployeeInfo,
    putEmployeeEmail,
    putEmployeeInfo,
} from '../api/employee'
import { getEmployeeInfoFormatterFromAPI } from '../formatter/employeeFormatter'
import { getEventInfoById } from '../api/event'
import { getRoleByName } from '../api/roles'

export const getEmployeeInfoService = (cif, eventId) => {
    return new Promise((resolve, reject) => {
        getEmployeeInfo(cif, eventId)
            .then(async (response) => {
                const eventData = await getEventInfoById(eventId)
                if (eventData.status !== 200 || !eventData?.data?.data) {
                    throw new Error('No hay ningun evento con este id')
                }

                const workingCenterId = eventData.data.data.attributes.centro_de_trabajos.data[0].id
                const workingCenterName = eventData.data.data.attributes.centro_de_trabajos.data[0].attributes.name

                if (
                    !response.data.data.length ||
                    (response.status === 200 &&
                        response.data?.data?.length > 0 &&
                        !response.data.data[0].attributes?.centro_de_trabajos.data.length)
                ) {
                    if (!eventData?.data?.data?.attributes?.accept_registrations) {
                        throw new Error('Este evento no acepta registros de usuarios sin alta previa')
                    }

                    let roleData = await getRoleByName(workingCenterName)
                    if (roleData.status !== 200 || !roleData?.data?.data?.length) {
                        throw new Error('No hay ningun role con este nombre')
                    }

                    const roleId = roleData.data.data[0].id

                    if (!response.data.data.length) {
                        const newEmployee = await postEmployeeInfo({
                            worker_cif: cif,
                            centro_de_trabajos: [workingCenterId],
                            employee_roles: [roleId],
                        })
                        if (newEmployee.status !== 200 || !newEmployee?.data?.data) {
                            throw new Error('No hemos podido crear tu usuario')
                        }

                        newEmployee.data.data = [newEmployee.data.data]
                        resolve(getEmployeeInfoFormatterFromAPI(newEmployee.data, workingCenterName))
                    } else {
                        const updatedData = {
                            centro_de_trabajos: {
                                connect: [workingCenterId],
                            },
                            employee_roles: {
                                connect: [roleId],
                            },
                        }

                        const updatedEmployee = await putEmployeeInfo(response.data.data[0].id, eventId, updatedData)
                        updatedEmployee.data.data = [updatedEmployee.data.data]
                        resolve(getEmployeeInfoFormatterFromAPI(updatedEmployee.data, workingCenterName))
                    }
                } else if (response.status === 200 && response.data?.data?.length > 0) {
                    resolve(getEmployeeInfoFormatterFromAPI(response.data, workingCenterName))
                } else {
                    throw new Error('No hemos encontrado tu usuario')
                }
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const putEmployeeInfoService = (employeeId, email) => {
    return new Promise((resolve, reject) => {
        putEmployeeEmail(employeeId, email)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data)
                } else throw new Error('No hemos verificado tu usuario')
            })
            .catch((error) => {
                reject(new Error('No hemos verificado tu usuario'))
            })
    })
}
