export const getEmployeeInfoFormatterFromAPI = (data, workingCenterName) => {
    let roles = []
    let array = data.data[0].attributes.employee_roles.data || []
    array = array.filter((role) => {
        if (role.attributes.type === 'is_working_center') {
            return role.attributes.role.toUpperCase() === workingCenterName.toUpperCase()
        }
        return true
    })

    array.map((role) => {
        roles.push(role.attributes.role)
    })

    return {
        id: data?.data[0]?.id,
        name: data?.data[0]?.attributes?.worker_name || '',
        surname1: data?.data[0]?.attributes?.worker_surname || '',
        surname2: data?.data[0]?.attributes?.worker_secondsurname || '',
        roles: roles || [],
    }
}
