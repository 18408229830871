import React, { useEffect } from 'react'
import { Card, CardBody, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../constants'
import { Colors } from '../../theme/colors'
import { useNavigate, useParams } from 'react-router-dom'

const Finish = () => {
    const { eventId } = useParams()
    const navigate = useNavigate()

    const employeeData = useSelector((state) => {
        return state.employeeReducer.employeeData
    })

    const eventData = useSelector((state) => {
        return state.eventReducer.eventData
    })

    useEffect(() => {
        localStorage.removeItem('employeeData')
    }, [])

    useEffect(() => {
        if (!eventData) {
            navigate(`/${eventId}`)
        }
    }, [])

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url(${BASE_URL + eventData?.backgroundImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                maxW={[400, 500, 600]}
                padding={'8px'}
                style={{ border: `2px solid ${eventData?.eventColor}` }}
                overflow="hidden"
                backgroundColor={Colors.background}
                variant="elevated">
                <CardBody>
                    <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                        <img src={BASE_URL + eventData?.logoUrl} style={{ objectFit: 'contain' }} alt="Logo" />
                    </div>
                    <div
                        style={{
                            marginTop: 60,
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                        }}>
                        <p
                            style={{
                                color: eventData?.eventColor || Colors.text,
                                marginTop: 15,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                fontWeight: 'bold',
                                fontSize: 32,
                            }}>
                            Ya has completado tu formación
                        </p>
                        <h1
                            style={{
                                color: Colors.text,
                                marginTop: 5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                fontSize: 25,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                            }}>
                            {' '}
                            {employeeData?.completeName}
                        </h1>
                    </div>
                    <p
                        style={{
                            color: Colors.text,
                            fontSize: 16,
                            marginTop: 24,
                            marginBottom: 24,
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            textAlign: 'center',
                        }}>
                        Gracias por tu atención. Si el evento debe mandarte tu acreditación, te la hará llegar al correo
                        que nos has facilitado.
                    </p>
                </CardBody>
            </Card>
        </div>
    )
}

export default Finish
