import ApiClient from '../axiosConfig'

export const getEventInfo = (eventId) => {
    const URL = `/events?filters[eventId][$eqi]=${eventId}&populate=*`
    return ApiClient.get(URL)
}

export const getEventInfoById = (id) => {
    const URL = `/events/${id}?populate=*`
    return ApiClient.get(URL)
}
