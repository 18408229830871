import {initialState} from '../initialState'
import {SET_EVENT_DATA, SET_EVENT_DATA_ERROR} from "../../actions/eventActions";

export const eventReducer = (
    state = initialState.eventReducer,
    action,
) => {
    switch (action.type) {
        case SET_EVENT_DATA:
            return {
                ...state,
                eventData: action.payload.eventData,
            }
        case SET_EVENT_DATA_ERROR:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return { ...state }
    }
}
