import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardBody, Checkbox, Input, Link, Select, Spinner, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../constants'
import { employeeActions } from '../../redux/actions/employeeActions'
import { useDispatch, useSelector } from 'react-redux'
import { eventActions } from '../../redux/actions/eventActions'
import { Colors } from '../../theme/colors'

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()

    const { eventId } = useParams()
    const [cif, setCif] = useState('')
    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [loader, setLoader] = useState(false)
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const [selectedLenguage, setSelectedLenguage] = useState('es')

    const { employeeData, error } = useSelector((state) => {
        return state.employeeReducer
    })

    const eventData = useSelector((state) => {
        return state.eventReducer.eventData
    })

    useEffect(() => {
        eventActions(dispatch).getEventData(eventId)
    }, [])

    useEffect(() => {
        if (employeeData && employeeData.roles && employeeData.roles.length > 0) {
            setLoader(false)
            navigate(`/${eventId}/video`)
        }
    }, [employeeData])

    useEffect(() => {
        if (error) {
            setLoader(false)
            toast({
                title: error,
                status: 'error',
                isClosable: true,
            })
        }
    }, [error])

    const onKeyPressed = (e) => {
        let key = e.key

        if (key === 'Enter') {
            handleLogin()
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            )
    }

    const validateConfirmEmail = () => {
        return email === confirmEmail
    }

    const handleLogin = () => {
        //Set language on session storage
        localStorage.setItem('language', selectedLenguage)

        if (cif && email && validateEmail(email) && checkboxChecked && validateConfirmEmail()) {
            setLoader(true)
            employeeActions(dispatch).getEmployeeData(cif, eventData.id, email)
        } else if (!cif) {
            toast({
                title: 'El campo DNI/NIE/PASS/ID es obligatorio',
                status: 'error',
                isClosable: true,
            })
        } else if (!checkboxChecked) {
            toast({
                title: 'Debes aceptar la política de privacidad',
                status: 'error',
                isClosable: true,
            })
        } else if (!validateConfirmEmail()) {
            toast({
                title: 'Los emails no coinciden',
                status: 'error',
                isClosable: true,
            })
        } else {
            toast({
                title: 'El email no es válido',
                status: 'error',
                isClosable: true,
            })
        }
    }

    return (
        <>
            {eventData ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: `url(${BASE_URL + eventData?.backgroundImageUrl})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        maxW={[400, 500, 600]}
                        padding={'8px'}
                        overflow="hidden"
                        backgroundColor={Colors.background}
                        variant="elevated">
                        <CardBody>
                            <Box style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={BASE_URL + eventData?.logoUrl}
                                    style={{ objectFit: 'contain', maxHeight: 200 }}
                                    alt="Logo"
                                />
                            </Box>
                            <Box mt={['30px', '40px']} mb={'20px'}>
                                <p style={{ color: eventData?.eventColor, fontWeight: '500', fontSize: 22 }}>
                                    DNI/NIE/PASS/ID
                                </p>
                                <Input
                                    borderColor={eventData?.eventColor}
                                    value={cif}
                                    color={Colors.text}
                                    onChange={(event) => setCif(event.target.value)}
                                    placeholder={'Introduce tu identificación fiscal'}
                                    variant={'outline'}
                                />
                            </Box>
                            <Box mb={'20px'}>
                                <p style={{ color: eventData?.eventColor, fontWeight: '500', fontSize: 22 }}>Email</p>
                                <Input
                                    borderColor={eventData?.eventColor}
                                    value={email}
                                    type={'email'}
                                    color={Colors.text}
                                    required
                                    onChange={(event) => setEmail(event.target.value)}
                                    placeholder={'Introduce tu email'}
                                    variant={'outline'}
                                />
                            </Box>
                            <Box mb={'20px'}>
                                <p style={{ color: eventData?.eventColor, fontWeight: '500', fontSize: 22 }}>
                                    Confirmar email
                                </p>
                                <Input
                                    borderColor={eventData?.eventColor}
                                    value={confirmEmail}
                                    type={'email'}
                                    color={Colors.text}
                                    required
                                    onChange={(event) => setConfirmEmail(event.target.value)}
                                    placeholder={'Confirma tu email'}
                                    variant={'outline'}
                                    onKeyPress={(e) => onKeyPressed(e)}
                                />
                                <p
                                    style={{
                                        color: Colors.text,
                                        fontWeight: 'normal',
                                        fontSize: 18,
                                        marginTop: 10,
                                    }}>
                                    Usaremos este email para enviarte la acreditación a la entrada del evento
                                </p>
                            </Box>
                            <p
                                style={{
                                    color: eventData?.eventColor,
                                    fontWeight: 'normal',
                                    fontSize: 20,
                                    marginTop: 10,
                                }}>
                                Selecciona el idioma en el que quieres visualizar el vídeo:
                            </p>
                            <Select
                                value={selectedLenguage}
                                onChange={(event) => setSelectedLenguage(event.target.value)}
                                variant="outline"
                                placeholder="Selecciona el idioma"
                                color={'white'}
                                borderColor={eventData?.eventColor}
                                mb="32px">
                                <option value="es" color={'#312f2f'}>
                                    Español
                                </option>
                                <option value="en" color={'#312f2f'}>
                                    Inglés
                                </option>
                            </Select>
                            <Checkbox
                                defaultChecked={checkboxChecked}
                                color={Colors.text}
                                mb={'32px'}
                                onChange={() => setCheckboxChecked(!checkboxChecked)}>
                                He leido y acepto la{' '}
                                <Link
                                    textDecor={'underline'}
                                    href="https://doc.clickup.com/2509401/p/h/2cjjt-11025/455b80315e74703"
                                    isExternal>
                                    política de privacidad
                                </Link>{' '}
                                y los{' '}
                                <Link
                                    textDecor={'underline'}
                                    href="https://doc.clickup.com/2509401/p/h/2cjjt-11045/377d0d889de3bf9"
                                    isExternal>
                                    términos y condiciones
                                </Link>
                                .
                            </Checkbox>
                            <Button
                                backgroundColor={
                                    eventData.eventSecondaryColor
                                        ? eventData.eventSecondaryColor
                                        : eventData?.eventColor
                                }
                                color={'#fff'}
                                isLoading={loader}
                                onClick={handleLogin}
                                style={{ width: '100%' }}
                                _hover={{
                                    opacity: 0.8,
                                }}>
                                Iniciar sesión
                            </Button>
                        </CardBody>
                    </Card>
                </div>
            ) : (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                </div>
            )}
        </>
    )
}

export default Login
