import React, {useEffect, useState} from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { BsCircle, BsCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { videoActions } from '../../redux/actions/videoActions'
import { BASE_URL } from '../../constants'
import { Colors } from '../../theme/colors'

const Sidebar = () => {
    const dispatch = useDispatch()

    const [videosToShow, setVideosToShow] = useState([])

    const { eventData } = useSelector((state) => {
        return state.eventReducer
    })

    const { videosByRole } = useSelector((state) => {
        return state.videoReducer
    })

    useEffect(() => {
        if (videosByRole) {
            const newVideoArray = [...videosByRole].sort((a, b) => {
                if (a.role === 'Riesgos comunes') {
                    return -1;
                } else if (b.role === 'Riesgos comunes') {
                    return 1;
                }

                if (a.role.includes('Centro de trabajo')) {
                    return -1;
                } else if (b.role.includes('Centro de trabajo')) {
                    return 1;
                }
                return a.role.localeCompare(b.role);
            })
            setVideosToShow(newVideoArray)
        }
    },[videosByRole])

    const renderHeader = (title, id) => {
        return (
            <Flex height={14} px={2} pb={2} alignItems={'flex-end'}>
                <Text fontSize={'lg'} as={'b'} color={Colors.text}>
                    {title}
                </Text>
            </Flex>
        )
    }

    const renderMenuItem = (video) => {
        return (
            <Flex
                key={video.id}
                width={'100%'}
                backgroundColor={Colors.background}
                borderBottom={`1px solid ${eventData?.eventColor}44`}
                cursor={'pointer'}
                transition={'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform'}
                transitionDuration={'0.2s'}
                _hover={{
                    backgroundColor: eventData?.eventColor + '44',
                }}
                onClick={() => {
                    videoActions(dispatch).selectVideo(video)
                }}>
                <Flex width={'50px'} minHeight={'50px'} justifyContent={'center'} alignItems={'center'}>
                    {video.hasSeen ? (
                        <BsCircleFill style={{ height: 20, width: 20, color: eventData?.eventColor }} />
                    ) : (
                        <BsCircle style={{ height: 20, width: 20, color: eventData?.eventColor }} />
                    )}
                </Flex>
                <Flex flex={1} borderLeft={`1px solid #FFFFFF`} boxSizing={'border-box'} p={2} alignItems={'center'}>
                    <Text fontSize={'sm'} color={Colors.text}>
                        {video.title}
                    </Text>
                </Flex>
            </Flex>
        )
    }

    const RenderSection = ({ section, key }) => {
        return (
            <Box key={key} marginBottom={2}>
                {renderHeader(section.role, section.id)}
                {section.videos.map((video) => {
                    return renderMenuItem(video)
                })}
            </Box>
        )
    }

    const RenderIcon = ({ image }) => {
        return (
            <Flex>
                <img
                    style={{ maxHeight: 120, width: '100%', objectFit: 'contain', padding: '16px' }}
                    src={image}
                    alt="logo"
                />
            </Flex>
        )
    }

    return (
        <Flex direction={'column'} h={'100%'} backgroundColor={Colors.background} justifyContent={'space-between'}>
            <Box h={'100%'} overflowY={'auto'} backgroundColor={Colors.background}>
                {videosToShow.map((section, index) => {
                    return <RenderSection key={index} section={section} />
                })}
            </Box>
            {eventData && <RenderIcon image={BASE_URL + eventData.logoUrl} />}
        </Flex>
    )
}

export default Sidebar
