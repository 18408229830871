// ACTION TYPES
import { getVideosRoleService } from '../../data/services/videosService'
import { getEmployeHasVideo } from '../../data/api/videos'
import { getEmployeeInfoService, putEmployeeInfoService } from '../../data/services/employeeService'
import { eventActions } from './eventActions'

export const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA'
export const SET_EMPLOYEE_DATA_ERROR = 'SET_EMPLOYEE_DATA_ERROR'
export const LOAD_EMPLOYEE_DATA = 'LOAD_EMPLOYEE_DATA'

export const employeeActions = (dispatch) => {
    const getEmployeeData = async (cif, eventId, email) => {
        dispatch({
            type: SET_EMPLOYEE_DATA_ERROR,
            payload: {
                error: '',
            },
        })

        try {
            const cifTrimmed = cif
                .trim()
                .replace(/-|_|\s/g, '')
                .toUpperCase()

            const employeeData = await getEmployeeInfoService(cifTrimmed, eventId, email)
            await putEmployeeInfoService(employeeData.id, email)

            dispatch({
                type: SET_EMPLOYEE_DATA,
                payload: {
                    employeeData,
                },
            })
        } catch (e) {
            console.error(e.message)
            dispatch({
                type: SET_EMPLOYEE_DATA_ERROR,
                payload: {
                    error: e.message,
                },
            })
        }
    }

    const setEmployeeData = async (navigate, eventId) => {
        const localStorageUser = localStorage.getItem('employeeData')
        const employeeData = JSON.parse(localStorageUser)
        if (!localStorageUser) {
            navigate(`/${eventId}`)
        } else {
            dispatch({
                type: LOAD_EMPLOYEE_DATA,
                payload: {
                    employeeData,
                },
            })
        }
        await eventActions(dispatch).getEventData(eventId)
    }

    return {
        getEmployeeData,
        setEmployeeData,
    }
}
