import React, { useState } from 'react'
import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, IconButton, Show } from '@chakra-ui/react'
import Sidebar from '../molecules/Sidebar'
import { BiMenu } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Colors } from '../../theme/colors'

const MainLayout = ({ children }) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(true)

    const { eventData } = useSelector((state) => {
        return state.eventReducer
    })

    const renderSideBar = () => {
        return (
            <>
                <Show above={'md'}>
                    <Box
                        h={'100%'}
                        minWidth={'300px'}
                        w={'25%'}
                        maxWidth={'400px'}
                        boxShadow={'0px 6px 20px ' + Colors.shadow}>
                        <Sidebar eventColor={eventData?.eventColor || '#29abe2'} />
                    </Box>
                </Show>
                <Show below={'md'}>
                    <Drawer isOpen={drawerIsOpen} placement="left" onClose={() => setDrawerIsOpen(false)}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <Sidebar eventColor={eventData?.eventColor || '#29abe2'} />
                        </DrawerContent>
                    </Drawer>
                </Show>
            </>
        )
    }

    return (
        <div
            style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'row',
                height: '100vh',
                backgroundColor: Colors.background,
            }}>
            {renderSideBar()}
            <Flex flex={1} direction={'column'}>
                <Show below={'md'}>
                    <Flex h={12} w={'100%'} backgroundColor={'#FAFAFA'} px={2} alignItems={'center'}>
                        <IconButton
                            icon={<BiMenu style={{ height: 24, width: 24 }} />}
                            onClick={() => setDrawerIsOpen(true)}
                        />
                    </Flex>
                </Show>
                <div style={{ overflow: 'auto', height: '100%', width: '100%', padding: 10 }}>{children}</div>
            </Flex>
        </div>
    )
}

export default MainLayout
