import axios from 'axios';
import {BASE_URL, TOKEN} from "../constants";

const API_URL = BASE_URL + "/api";

export default axios.create({
    baseURL: API_URL,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`,
    },
});
