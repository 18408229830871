import ApiClient from '../axiosConfig'

export const getEmployeeInfo = (cif, eventId) => {
    const params = {
        'populate[employee_roles]': '*',
        'populate[centro_de_trabajos][fields][0]': 'cif',
        'populate[centro_de_trabajos][fields][1]': 'name',
        'populate[centro_de_trabajos][filters][event][id][$eqi]': eventId,
        'filters[worker_cif][$eqi]': cif,
    }
    const URL = `/employees`
    return ApiClient.get(URL, { params })
}

export const postEmployeeInfo = (data) => {
    const params = {
        'populate[employee_roles]': '*',
        'populate[centro_de_trabajos][fields][0]': 'cif',
        'populate[centro_de_trabajos][fields][1]': 'name',
    }

    const URL = `/employees`
    return ApiClient.post(URL, { data }, { params })
}

export const getEmployeeInfoById = (employeeId) => {
    const URL = `/employees?populate=*&filters[id][$eqi]=${employeeId}`
    return ApiClient.get(URL)
}

export const putEmployeeEmail = (employeeId, newData) => {
    const URL = `/employees/${employeeId}`
    return ApiClient.put(URL, {
        data: {
            worker_mail: newData,
        },
    })
}

export const putEmployeeInfo = (employeeId, eventId, data) => {
    const URL = `/employees/${employeeId}`
    const params = {
        'populate[employee_roles]': '*',
        'populate[centro_de_trabajos][fields][0]': 'cif',
        'populate[centro_de_trabajos][fields][1]': 'name',
        'populate[centro_de_trabajos][filters][event][id][$eqi]': eventId,
    }

    return ApiClient.put(URL, { data }, { params })
}
