import { getEmployeHasVideo, getVideosRole, postRegisterVideo } from '../api/videos'
import {
    getEmployeeHasVideoFormatterFromApi,
    getVideosRoleFormatterFromApi,
    getVideosRolesFormatterToApi,
    postRegisterVideoFormatterToApi,
} from '../formatter/videosFormatter'

export const getVideosRolesService = (roles) => {
    return new Promise((resolve, reject) => {
        getVideosRole(getVideosRolesFormatterToApi(roles))
            .then((response) => {
                if (response.status === 200) {
                    const formattedData = getVideosRoleFormatterFromApi(response.data, roles)
                    resolve(formattedData)
                } else throw new Error('No se han podido obterner los vídeos')
            })
            .catch((error) => {
                console.error(error)
                reject(error)
            })
    })
}

export const postRegisterVideoService = (employeeId, videoId) => {
    return new Promise((resolve, reject) => {
        postRegisterVideo(postRegisterVideoFormatterToApi(employeeId, videoId))
            .then((response) => {
                if (response.status === 200) {
                    resolve('Se ha registrado correctamente')
                } else throw new Error('No se ha registrado')
            })
            .catch((error) => {
                console.error(error)
                reject(error)
            })
    })
}

export const getEmployeeHasVideoService = (employeeId) => {
    return new Promise((resolve, reject) => {
        getEmployeHasVideo(employeeId)
            .then((response) => {
                if (response.status === 200) {
                    resolve(getEmployeeHasVideoFormatterFromApi(response.data))
                } else throw new Error('No se ha podido obtener el listado de vídeos')
            })
            .catch((error) => {
                console.error(error)
                reject(error)
            })
    })
}
