import {initialState} from '../initialState'
import {SET_SELECTED_VIDEOS_ACTION, SET_VIDEOS_ACTION} from "../../actions/videoActions";

export const videoReducer = (
    state = initialState.videoReducer,
    action,
) => {
    switch (action.type) {
        case SET_VIDEOS_ACTION:
            return {
                ...state,
                videosByRole: action.payload.videosByRole,
            }
        case SET_SELECTED_VIDEOS_ACTION:
            return {
                ...state,
                selectedVideo: action.payload.video,
            }
        default:
            return { ...state }
    }
}
