// Light mode
// export const Colors = {
//     background: '#FFFFFF',
//     text: '#1A202C',
//     shadow: 'rgba(32,39,45,0.15)'
// }

// Dark mode
export const Colors = {
    background: '#312f2f',
    text: '#FAFAFA',
    textLink: 'rgba(250,250,250,0.75)',
    shadow: 'rgba(0,0,0,0.76)',
}
