import React, { useEffect, useState } from 'react'
import MainLayout from '../../components/layout/MainLayout'
import ReactPlayer from 'react-player'
import { MdOndemandVideo } from 'react-icons/md'
import { videoActions } from '../../redux/actions/videoActions'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Box, CircularProgress, Flex, Text, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { employeeActions } from '../../redux/actions/employeeActions'
import { Colors } from '../../theme/colors'

const Video = () => {
    const dispatch = useDispatch()
    const store = useStore()
    const navigate = useNavigate()
    const { eventId } = useParams()
    const toast = useToast()

    const { videosByRole, selectedVideo } = useSelector((state) => {
        return state.videoReducer
    })

    const { id: employeeId, roles } = useSelector((state) => {
        return state.employeeReducer?.employeeData || { id: null, roles: [] }
    })

    const { error } = useSelector((state) => {
        return state.employeeReducer?.error
    })

    const eventData = useSelector((state) => {
        return state.eventReducer.eventData
    })

    const [loadingVideos, setLoadingVideos] = useState(true)
    const [loadingUserHasSeenVideos, setLoadingUserHasSeenVideos] = useState(true)

    useEffect(() => {
        setLoadingVideos(true)
        if (!employeeId || roles.length) {
            employeeActions(dispatch).setEmployeeData(navigate, eventId)
        }
    }, [])

    useEffect(() => {
        if (roles.length) {
            videoActions(dispatch).getVideosByRoles(roles)
        }
    }, [roles])

    useEffect(() => {
        if (videosByRole && videosByRole.length > 0 && loadingUserHasSeenVideos) {
            setLoadingUserHasSeenVideos(false)
            videoActions(dispatch).getUserHasSeenVideos(employeeId, store, navigate, eventId)
        }
    }, [videosByRole])

    useEffect(() => {
        if (error) {
            setLoadingVideos(false)
            toast({
                title: 'No se han podido cargar los vídeos de tu usuario',
                status: 'error',
                isClosable: true,
            })
        }
    }, [error])

    useEffect(() => {
        if (selectedVideo) {
            setLoadingVideos(false)
        }
    }, [selectedVideo])

    const handleEndVideo = () => {
        videoActions(dispatch).registerUserHasSeenVideo(employeeId, selectedVideo?.id, store, navigate, eventId)
    }

    return (
        <MainLayout>
            <Box h={'100%'}>
                {loadingVideos ? (
                    <CircularProgress
                        value={60}
                        size="120px"
                        thickness="8px"
                        style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        isIndeterminate
                        color={eventData?.eventColor || '#29abe2'}
                    />
                ) : (
                    <Box p={4} h={'100%'} overflow={'hidden'}>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} pb={4}>
                            <MdOndemandVideo color={Colors.text} style={{ height: 40, width: 40, marginRight: 16 }} />
                            <Text fontSize={'xl'} as={'b'} color={Colors.text}>
                                {selectedVideo?.title}
                            </Text>
                        </Box>
                        <Flex h={'93%'}>
                            <ReactPlayer
                                width={'100%'}
                                height={'100%'}
                                onEnded={handleEndVideo}
                                controls
                                autoplay
                                url={
                                    localStorage.getItem('language') === 'en'
                                        ? selectedVideo?.url_en
                                        : selectedVideo?.url
                                }
                            />
                        </Flex>
                    </Box>
                )}
            </Box>
        </MainLayout>
    )
}

export default Video
