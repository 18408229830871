// ACTION TYPES
import {
    getEmployeeHasVideoService,
    getVideosRolesService,
    postRegisterVideoService,
} from '../../data/services/videosService'

export const SET_VIDEOS_ACTION = 'SET_VIDEOS_ACTION'
export const SET_SELECTED_VIDEOS_ACTION = 'SET_SELECTED_VIDEOS_ACTION'

export const videoActions = (dispatch) => {
    const getVideosByRoles = async (roles) => {
        try {
            const videosByRole = await getVideosRolesService(roles)
            dispatch({
                type: SET_VIDEOS_ACTION,
                payload: {
                    videosByRole,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    const selectVideo = async (video) => {
        try {
            dispatch({
                type: SET_SELECTED_VIDEOS_ACTION,
                payload: {
                    video,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    const goToNextVideo = async (store, navigate, eventId) => {
        const findUnseenVideo = (storeVideos) => {
            for (let i = 0; i < storeVideos.length; i++) {
                const videos = storeVideos[i].videos
                for (let j = 0; j < videos.length; j++) {
                    const video = videos[j]
                    if (video.hasSeen === false) {
                        return video
                    }
                }
            }

            return null
        }

        const storeVideos = store.getState().videoReducer.videosByRole
        const nextVideo = findUnseenVideo(storeVideos)

        if (nextVideo) {
            dispatch({
                type: SET_SELECTED_VIDEOS_ACTION,
                payload: {
                    video: nextVideo,
                },
            })
        } else {
            navigate(`/${eventId}/finish`)
        }
    }

    const getUserHasSeenVideos = async (employeeId, store, navigate, eventId) => {
        try {
            const videosSeenByUser = await getEmployeeHasVideoService(employeeId)

            const storeVideos = store.getState().videoReducer.videosByRole
            const newVideos = storeVideos.map((videoRole) => {
                const newVideoRole = { ...videoRole }
                newVideoRole.videos = newVideoRole.videos.map((video) => {
                    const newVideo = { ...video }
                    newVideo.hasSeen = videosSeenByUser.some((videoId) => videoId === video.id)
                    return newVideo
                })
                return newVideoRole
            })

            dispatch({
                type: SET_VIDEOS_ACTION,
                payload: {
                    videosByRole: newVideos,
                },
            })

            goToNextVideo(store, navigate, eventId)
        } catch (e) {
            console.error(e)
        }
    }

    const getDemoUserHasSeenVideos = async (employeeId, store, navigate, eventId, markVideoId) => {
        try {
            const storeVideos = store.getState().videoReducer.videosByRole
            debugger
            const newVideos = storeVideos.map((videoRole) => {
                const newVideoRole = { ...videoRole }
                newVideoRole.videos = newVideoRole.videos.map((video) => {
                    const newVideo = { ...video }
                    newVideo.hasSeen = video.id === markVideoId ? true : video.hasSeen
                    return newVideo
                })
                return newVideoRole
            })
            debugger

            dispatch({
                type: SET_VIDEOS_ACTION,
                payload: {
                    videosByRole: newVideos,
                },
            })

            goToNextVideo(store, navigate, eventId)
        } catch (e) {
            console.error(e)
        }
    }

    const registerUserHasSeenVideo = async (employeeId, videoId, store, navigate, eventId) => {
        try {
            if (employeeId > 5) {
                await postRegisterVideoService(employeeId, videoId)
                getUserHasSeenVideos(employeeId, store, navigate, eventId)
            } else {
                getDemoUserHasSeenVideos(employeeId, store, navigate, eventId, videoId)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return {
        getVideosByRoles,
        selectVideo,
        goToNextVideo,
        getUserHasSeenVideos,
        registerUserHasSeenVideo,
    }
}
