import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {videoReducer} from "./reducers/reducers/videoReducer";
import {employeeReducer} from "./reducers/reducers/employeeReducer";
import {eventReducer} from "./reducers/reducers/eventReducer";

const reducers = {
    videoReducer,
    employeeReducer,
    eventReducer,
}

export default function configureStore(initialState) {
    const middlewares = [thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = compose(...enhancers)
    const combinedReducers = combineReducers({
        ...reducers,
    })

    return createStore(combinedReducers, initialState, composedEnhancers)
}
