const PROD_BASE_URL = "https://cms.zion.onabitz.com"
const DEV_BASE_URL = "http://localhost:1337"

export const BASE_URL = PROD_BASE_URL //__DEV__ ? DEV_BASE_URL : PROD_BASE_URL


const PROD_TOKEN = "1a79208bf5b2a95f547265a5ed048d9cb1b6c15a760b6a7be48b82f1e8e9afed2f472208a7145de361c8aa2bc6223d7b3e936077e2723ad192755f4838f8d679985d9dcf2e54d71be7e99a733f30189eb813e346d1bb1cdcd824805f717075c2d0452d49615c7db63f6ec4d4a5a424d8121f749795d4235e369763792ac8286f"
const DEV_TOKEN = "5e7c6112382aff0b5dfa61cf029351fdfbace4491d7269a5fc79aa948d87c38c7132dbff3fccfb827c3b070da5511973d8aebf00ebb6bca3c0c336a5581014020958e6024c793ef3910aed38ec2f82122c2fd1fb163aa3f60bf92091858097f5fe8cf13bfbabaf5be3d97ffaf0f40d717a0d7dbee6aadd0041db0dbe0f744d8c"

export const TOKEN = PROD_TOKEN //__DEV__ ? DEV_TOKEN : PROD_TOKEN
