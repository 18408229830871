import ApiClient from '../axiosConfig'

export const getVideosRole = (roles) => {
    const URL = `/videos?populate=employee_roles&sort=id&filters[employee_roles][role][$eqi]=General${roles}`
    return ApiClient.get(URL)
}

export const postRegisterVideo = (data) => {
    const URL = '/employee-has-videos'
    return ApiClient.post(URL, data)
}

export const getEmployeHasVideo = (employeeId) => {
    const URL = `/employee-has-videos?populate[0]=videos&filters[employees][id]=${employeeId}`
    return ApiClient.get(URL)
}
