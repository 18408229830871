// ACTION TYPES
import {getVideosRoleService} from "../../data/services/videosService";
import {getEmployeHasVideo} from "../../data/api/videos";
import {getEmployeeInfoService, putEmployeeInfoService} from "../../data/services/employeeService";
import {getEventInfoService} from "../../data/services/eventService";

export const SET_EVENT_DATA = 'SET_EVENT_DATA';
export const SET_EVENT_DATA_ERROR = 'SET_EVENT_DATA_ERROR';

export const eventActions = (dispatch) => {
    const getEventData = async (eventId) => {
        try {
            const eventData = await getEventInfoService(eventId)

            dispatch({
                type: SET_EVENT_DATA,
                payload: {
                    eventData
                }
            })
        } catch (e) {
            console.error(e.message)
            dispatch({
                type: SET_EVENT_DATA_ERROR,
                payload: {
                    error: e.message
                }
            })
        }
    }

    return {
        getEventData
    }
}
