import {getEventInfo} from "../api/event";
import {getEventInfoFromAPI} from "../formatter/eventFormatter";

export const getEventInfoService = (eventId) => {
    return new Promise((resolve, reject) => {
        getEventInfo(eventId)
            .then(response => {
                if (response.status === 200 && response.data.data.length > 0) {
                    resolve(getEventInfoFromAPI(response.data.data[0]))
                } else throw new Error('No se ha podido obtener los datos del evento');
            })
            .catch(error => {
                console.error(error);
                reject(error);
            })
    })
}
