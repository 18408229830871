export const getVideosRoleFormatterFromApi = (data, userRoles) => {
    const mappedData = data.data

    const generalVideos = []
    const centerVideos = []
    const rolesVideos = []

    mappedData.forEach((item) => {
        const employeeRoles = item.attributes.employee_roles.data
        const video = {
            id: item.id,
            title: item.attributes.name,
            url: item.attributes.link,
            url_en: item?.attributes?.link_en,
            hasSeen: false,
        }

        employeeRoles.forEach((role) => {
            let arrayToSearch = generalVideos
            if (role.attributes.type === 'is_working_center') arrayToSearch = centerVideos
            else if (role.attributes.type === 'is_role') arrayToSearch = rolesVideos

            if (item.attributes)
                if (userRoles.includes(role.attributes.role)) {
                    const roleIndex = arrayToSearch.findIndex((r) => r.role === role.attributes.role)

                    if (roleIndex === -1) {
                        arrayToSearch.push({
                            id: role.id,
                            role: role.attributes.role,
                            videos: [video],
                        })
                    } else {
                        arrayToSearch[roleIndex].videos.push(video)
                    }
                }
        })
    })

    return [...generalVideos, ...centerVideos, ...rolesVideos]
}

export const getVideosRolesFormatterToApi = (data) => {
    let arr = data || []
    let str = ''
    arr.map((role) => {
        if (role !== 'General') {
            str = str + '&filters[employee_roles][role][$eqi]=' + role
        }
    })
    return str
}

export const postRegisterVideoFormatterToApi = (employeeId, videoId) => {
    return {
        data: {
            employees: {
                id: employeeId,
            },
            videos: {
                id: videoId,
            },
        },
    }
}

export const getEmployeeHasVideoFormatterFromApi = (data) => {
    return data.data.map((item) => item.attributes.videos.data[0].id)
}
